import { DataStoreDispatchActionsT } from './createDataStoreTypes';
import { SimulationReducerStateT } from './simulationReducerTypes';

/* ----------------------------- All Dispatches ----------------------------- */
export type SimulationDispatchActionsT =
    | DataStoreDispatchActionsT
    | DispatchDisplayModalWarningT
    | DispatchCloseModalWarningT;

/* ----------------------------- Actions Types ------------------------------ */
export enum SimulationActionTypes {
    DISPLAY_MODAL_WARNING = 'SIMULATION_DISPLAY_WARNING',
    CLOSE_MODAL_WARNING = 'SIMULATION_CLOSE_WARNING',
}

/* -------------------------- Modal Warning ------------------------- */
export type DispatchDisplayModalWarningT = {
    type: SimulationActionTypes.DISPLAY_MODAL_WARNING;
    payload: Pick<SimulationReducerStateT, 'modalWarning'>;
};

export type DispatchCloseModalWarningT = {
    type: SimulationActionTypes.CLOSE_MODAL_WARNING;
    payload: Pick<SimulationReducerStateT, 'modalWarning'>;
};
