import { geoJsonFeatureCollectionT, geoJsonFeatureT } from '@components/Map/MapTypes';
import { FieldT } from '@entities/fieldEntity/fieldEntity.types';
import ShapeFileService from '@services/shapeService/shapeFileService';
import ShapeService from '@services/shapeService/shapeService';
import { Feature } from '@services/shapeService/shapeServiceTypes';
import { FarmSeasonFieldT as FarmSeasonFieldTDeprecated } from '@store/reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { Optional } from '@utils/OptionalType';
import { Override } from '@utils/Override';
import i18n from 'i18next';

const validateUploadedFields = (
    uploadedFields: Optional<FarmSeasonFieldTDeprecated, 'id'>[],
    fields: FieldT[],
    farmBoundariesMask: geoJsonFeatureCollectionT | null,
    pastFields: Array<FarmSeasonFieldTDeprecated>,
) => {
    const response = {
        fieldsToAdd: [] as Optional<FarmSeasonFieldTDeprecated, 'id'>[],
        hasFieldsIntersectingExistings: false,
        hasFieldsOutsideBoundaries: false,
        hasProcessingErrors: false,
    };
    const formPolygons = fields.map((f) => f.polygon).filter((p) => !!p) as geoJsonFeatureT[];

    // we remove fields with invalid coordinates not handled by the platform
    response.fieldsToAdd = uploadedFields.filter((field) => {
        const hasInvalidCoordinates = ShapeService.hasInvalidCoordinates(field.polygon as Feature);
        if (!!hasInvalidCoordinates) {
            response.hasProcessingErrors = true;
            return false;
        }

        return true;
    });

    // we remove fields intersecting existing ones
    response.fieldsToAdd = response.fieldsToAdd.filter((field) => {
        const intersect = ShapeService.intersectPolygons(field.polygon as Feature, formPolygons); // REFACTOR how the FieldPolygonT can match a general Feature?
        if (intersect === true) {
            response.hasFieldsIntersectingExistings = true;
            return false; // skip this field
        }
        if (intersect === 'processingError') {
            response.hasProcessingErrors = true;
            return false;
        }
        return true;
    });

    // we remove fields intersecting the farm boundaries (if not baseline)
    if (farmBoundariesMask) {
        response.fieldsToAdd = response.fieldsToAdd.filter((field) => {
            const intersect = ShapeService.intersectFarmBoundaries(field.polygon as Feature, farmBoundariesMask);
            if (intersect === true) {
                response.hasFieldsOutsideBoundaries = true;
                return false; // skip this field
            }
            if (intersect === 'processingError') {
                response.hasProcessingErrors = true;
                return false;
            }
            return true;
        });
    }

    //compare fields with past fields to rename them accordingly
    if (Array.isArray(pastFields) && pastFields.length > 0) {
        response.fieldsToAdd.forEach((field) => {
            pastFields.forEach((pastField) => {
                const isIntersecting = ShapeService.intersectPolygonWithPercentage(
                    field.polygon,
                    pastField.polygon,
                    85,
                );

                if (isIntersecting) {
                    field.name = pastField.name;
                }
            });
        });
    }

    // we rename default name to increment on top of existing default names (if already exist default names and if uploaded fiedls have default names)
    if (
        fields.some((f) => f.name?.startsWith(ShapeFileService.defaultFieldNameSlug)) &&
        response.fieldsToAdd.filter((f) => !f.name.startsWith(ShapeFileService.defaultFieldNameSlug)).length === 0
    ) {
        response.fieldsToAdd = _renameDefaultFieldNames(
            response.fieldsToAdd,
            fields,
            ShapeFileService.defaultFieldNameSlug,
        );
    }

    return {
        uploadedFieldsToAdd: response.fieldsToAdd,
        hasFieldsIntersectingExistings: response.hasFieldsIntersectingExistings,
        hasFieldsOutsideBoundaries: response.hasFieldsOutsideBoundaries,
        hasProcessingErrors: response.hasProcessingErrors,
    };
};

/* rename uploaded fields starting with the highest index of existing fields that contains the default field name */
const _renameDefaultFieldNames = (
    uploadedFields: Optional<FarmSeasonFieldTDeprecated, 'id'>[],
    fields: Partial<FieldT>[],
    defaultFieldNameSlug: string,
) => {
    // get highest field name index
    const fieldsWithDefaultName = fields.filter((field) => field.name?.startsWith(defaultFieldNameSlug)) as Override<
        Partial<FieldT>,
        { name: string }
    >[];
    const fieldIndexes = fieldsWithDefaultName.map((field) => field.name.split(' ')[1]);
    fieldIndexes.sort((a, b) => parseInt(a) - parseInt(b));
    const highestFieldNameIndex = parseInt(fieldIndexes[fieldIndexes.length - 1]);

    // rename uploaded fields from highest index
    return uploadedFields.map((f, i) => ({
        ...f,
        name: `${i18n.t(defaultFieldNameSlug)} ${highestFieldNameIndex + i + 1}`,
    }));
};

export default () => ({ validateUploadedFields });
