/* eslint-disable @typescript-eslint/no-explicit-any */

import { OrganicFertilizationT } from '@reducers/FertiliserReducer/FertiliserReducerTypes';

import { BaselineFormT } from '../pages/Baseline/BaselineTypes';
import { FarmSimulationFormT } from '../pages/StepFarm/StepFarmTypes';

import createDataStore from './createDataStore';
import { CropGroupT, FarmSimulationT, SimulationChangesT } from './DataTypes';

const getFarmSimulation = createDataStore<FarmSimulationT, void, 'GET', 'farmSimulation'>(
    'GET',
    'farmSimulation',
    '/simulation',
    'farm',
);

const updateFarmSimulation = createDataStore<FarmSimulationT, { farm: FarmSimulationFormT }, 'PUT', 'farmSimulation'>(
    'PUT',
    'farmSimulation',
    '/simulation',
    'farm',
);

const createBaseline = createDataStore<FarmSimulationT, BaselineFormT, 'POST', 'farmSimulation'>(
    'POST',
    'farmSimulation',
    '/simulation/cropcft',
    'farm',
);

const getSimulationChanges = createDataStore<SimulationChangesT, void, 'GET', 'simulationChanges'>(
    'GET',
    'simulationChanges',
    '/simulation/changes',
);

const createSimulationChanges = createDataStore<SimulationChangesT, BaselineFormT, 'POST', 'simulationChanges'>(
    'POST',
    'simulationChanges',
    '/simulation/changes',
);

const getCropsGroup = createDataStore<CropGroupT[], void, 'GET', 'cropGroups'>(
    'GET',
    'cropGroups',
    '/simulation/crop-groups',
);

const getSimulationFertilisers = createDataStore<
    OrganicFertilizationT[],
    { countryId: number },
    'GET',
    'organicFertilisers'
>('GET', 'organicFertilisers', '/simulation/fertilisers');

/** Stores generated for entities. */
const dataStoresMap = {
    [getFarmSimulation.actionName]: getFarmSimulation,
    [updateFarmSimulation.actionName]: updateFarmSimulation,
    [createBaseline.actionName]: createBaseline,
    [getCropsGroup.actionName]: getCropsGroup,
    [getSimulationChanges.actionName]: getSimulationChanges,
    [createSimulationChanges.actionName]: createSimulationChanges,
    [getSimulationFertilisers.actionName]: getSimulationFertilisers,
};

export default dataStoresMap;
