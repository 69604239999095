/* eslint-disable @typescript-eslint/no-explicit-any */
import { GetResetT, RESET } from '@reducers/RootTypes';

import dataStoresMap from './dataStoresCreated';
import { DataStoreReducerStateT } from './createDataStoreTypes';
import { SimulationActionTypes, SimulationDispatchActionsT } from './simulationActionsTypes';
import { SimulationReducerStateT } from './simulationReducerTypes';
import { Reducer } from '@reduxjs/toolkit';

export const defaultSimulationState: SimulationReducerStateT = {
    ...(Object.values(dataStoresMap).reduce(
        (allStoreStates, store) => ({ ...allStoreStates, ...store.state }),
        {},
    ) as DataStoreReducerStateT),
    modalWarning: null,
};

const simulationReducer: Reducer<SimulationReducerStateT, SimulationDispatchActionsT | GetResetT> = (
    state,
    action,
): SimulationReducerStateT => {
    if (!state) {
        return defaultSimulationState;
    }

    switch (action.type) {
        case SimulationActionTypes.DISPLAY_MODAL_WARNING:
        case SimulationActionTypes.CLOSE_MODAL_WARNING:
            return { ...state, modalWarning: { ...action.payload.modalWarning } };

        case RESET:
            return {
                ...defaultSimulationState,
            };

        default:
            if (
                // Update state for generated entities
                Object.values(dataStoresMap)
                    .reduce(
                        (allStoreTypes: any, store) => [...allStoreTypes, ...Object.values(store.dispatchTypes)],
                        [],
                    )
                    .includes(action.type)
            ) {
                return { ...state, ...action.payload }; // we make reducer state match these payloads
            }

            return state;
    }
};

export default simulationReducer;
