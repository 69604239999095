import simulationReducer from '@components/Simulation/store/simulationReducer';
import authReducer from '@reducers/AuthReducer/AuthReducer';
import mapReducer from '@reducers/MapReducer/MapReducer';
import { combineReducers } from 'redux';

import { api } from '@api/api.service';
import { api2 } from '@api/api2.service';
import { invitationReducer } from '@api/invitation.slice';
import AppReducer from './AppReducer/AppReducer';
import BillingDetailsReducer from './BillingDetails/BillingDetails';
import ErrorsReducer from './ErrorsReducer/ErrorsReducer';
import farmReducer from './FarmReducer/FarmReducer';
import FarmSeasonCropReducer from './FarmSeasonCropReducer/FarmSeasonCropReducer';
import FarmSeasonFieldReducer from './FarmSeasonFieldReducer/FarmSeasonFieldReducer';
import FarmSeasonLivestockReducer from './FarmSeasonLivestockReducer/FarmSeasonLivestockReducer';
import farmSeasonReducer from './FarmSeasonReducer/FarmSeasonReducer';
import FertiliserReducer from './FertiliserReducer/FertiliserReducer';
import notificationReducer from './NotificationReducer/NotificationReducer';
import routingReducer from './RoutingReducer/RoutingReducer';
import staticReducer from './StaticReducer/StaticReducer';
import ModalFieldsSuggestionSlice from '@pages/HomePage/Installation/components/ModalFieldsSuggestion/ModalFieldsSuggestion.slice';
import subscriptionReducer from './SubscriptionReducer/SubscriptionReducer';
import ModalNoFieldsSuggestionSlice from '@pages/HomePage/Installation/components/ModalNoFieldsSuggestion/ModalNoFieldsSuggestion.slice';
import SuggestionSlice from './Onboarding/Suggestion.slice';

const RootReducer = combineReducers({
    [api.reducerPath]: api.reducer,
    [api2.reducerPath]: api2.reducer,
    app: AppReducer,
    auth: authReducer,
    subscription: subscriptionReducer,
    farm: farmReducer,
    farmSeasonLivestock: FarmSeasonLivestockReducer,
    farmSeason: farmSeasonReducer,
    static: staticReducer,
    routing: routingReducer,
    map: mapReducer,
    farmSeasonField: FarmSeasonFieldReducer,
    simulation: simulationReducer,
    farmSeasonCrop: FarmSeasonCropReducer,
    fertiliser: FertiliserReducer,
    errors: ErrorsReducer,
    notifications: notificationReducer,
    onboarding: combineReducers({
        modalFieldsSuggestion: ModalFieldsSuggestionSlice,
        modalNoFieldsSuggestion: ModalNoFieldsSuggestionSlice,
        suggestions: SuggestionSlice,
    }),
    billingDetails: BillingDetailsReducer,
    invitation: invitationReducer,
});

export default RootReducer;
