/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import request from '@services/apiService/apiService';
import { AxiosResponse } from 'axios';
import { Action, Dispatch } from '@reduxjs/toolkit';

function capitalizeFirstLetter<T extends string>(str: T) {
    return `${str.charAt(0).toUpperCase()}${str.slice(1)}` as Capitalize<T>;
}

/**
 * Generate what is needed for a "data" store. Reducer state that match payloads, dispatch types, action to dispatch
 * @param method axios method + name the state "createData" for POST, "updateData" for PUT, "getData" for GET + name the dispatch type "CREATE_DATA" | "GET_DATA"..
 * @param dataName name the state keys and dispatch types
 * @param isCollection specify if we want to get an array of entities from BE. (add a "s" to the dataName if yes)
 * @param endpoint to request BE
 * @param keyResponseAccess needed if BE return some keys in response. 'farm' will access data['farm'] for payload.
 * @returns state to add for reducer initialState, action to dispatch, types for reducer match.
 */
const createDataStore = <
    DataG extends Record<string, any>,
    ParamsG,
    MethodG extends 'GET' | 'PUT' | 'POST', // This could be an array to generate consistent crud around 1 data
    DataNameG extends string,
>(
    method: MethodG,
    dataName: DataNameG,
    endpoint: string,
    keyResponseAccess?: string,
) => {
    const methodName = `${
        method === 'PUT' ? 'update' : method === 'POST' ? 'create' : 'get'
    }` as `${MethodG extends 'PUT' ? 'update' : MethodG extends 'POST' ? 'create' : 'get'}`;
    const actionName = `${methodName}${capitalizeFirstLetter(dataName)}` as `${typeof methodName}${Capitalize<
        typeof dataName
    >}`;

    /* ---------------------------- build reducer state --------------------------- */
    const stateKeys = {
        data: dataName,
        actionLoading: `${actionName}Loading` as `${typeof actionName}Loading`,
        actionError: `${actionName}Error` as `${typeof actionName}Error`,
        dataLoaded: `${dataName}Loaded` as `${typeof dataName}Loaded`,
    };

    const state = {
        [stateKeys.data]: null,
        [stateKeys.actionLoading]: false,
        [stateKeys.actionError]: null,
        [stateKeys.dataLoaded]: false,
    } as StateT;

    type StateT = { [k in typeof stateKeys.data]: DataG } & { [k in typeof stateKeys.actionLoading]: boolean } & {
        [k in typeof stateKeys.actionError]: string;
    } & { [k in typeof stateKeys.dataLoaded]: boolean };

    /* -------------------------- Build dispatch types ------------------------- */
    const dispatchTypes = {
        action: `${methodName.toUpperCase()}_${dataName.toUpperCase()}`,
        actionSuccess: `${methodName.toUpperCase()}_${dataName.toUpperCase()}_SUCCESS`,
        actionFailed: `${methodName.toUpperCase()}_${dataName.toUpperCase()}_FAILED`,
        actionFailedClean: `${methodName.toUpperCase()}_${dataName.toUpperCase()}_FAILED_CLEAN`,
    } as DispatchTypesMapT;

    type DispatchTypesMapT = {
        action: `${Uppercase<typeof methodName>}_${Uppercase<typeof dataName>}`;
        actionSuccess: `${Uppercase<typeof methodName>}_${Uppercase<typeof dataName>}_SUCCESS`;
        actionFailed: `${Uppercase<typeof methodName>}_${Uppercase<typeof dataName>}_FAILED`;
        actionFailedClean: `${Uppercase<typeof methodName>}_${Uppercase<typeof dataName>}_FAILED_CLEAN`;
    };

    /* -------------------------- Build action to dispatch ------------------------- */

    const action =
        (params: ParamsG) =>
        async (dispatch: Dispatch<Action<DispatchTypesMapT[keyof DispatchTypesMapT]>>): Promise<DataG | false> => {
            try {
                // dispatch action
                dispatch({
                    type: dispatchTypes.action,
                    payload: { [stateKeys.actionError]: null, [stateKeys.actionLoading]: true } as Partial<StateT>,
                });

                const { data }: AxiosResponse<DataG> = await request({
                    url: endpoint,
                    method,
                    data: params,
                });

                // dispatch action success
                dispatch({
                    type: dispatchTypes.actionSuccess,
                    payload: {
                        [stateKeys.data]: keyResponseAccess ? data[keyResponseAccess] : data,
                        [stateKeys.actionLoading]: false,
                        [stateKeys.dataLoaded]: true,
                    } as Partial<StateT>,
                });

                return keyResponseAccess ? data[keyResponseAccess] : data;
            } catch (error) {
                const errors = error as { message: string; list: string[] };

                if (!errors.message) {
                    console.warn('the backend errors are not compatible with the "dataApiStore"');
                }

                // dispatch action failed
                dispatch({
                    type: dispatchTypes.actionFailed,
                    payload: {
                        [stateKeys.actionError]: errors.message,
                        [stateKeys.actionLoading]: false,
                    } as Partial<StateT>,
                });

                setTimeout(() => {
                    dispatch({
                        type: dispatchTypes.actionFailedClean,
                        payload: { [stateKeys.actionError]: null } as Partial<StateT>,
                    });
                }, 10000);

                return false;
            }
        };

    return {
        state,
        action,
        actionName,
        dispatchTypes,
    };
};
export default createDataStore;
